$neutral-color-000: #10100E;
$neutral-color-010: #34322E;
$neutral-color-020: #504D46;
$neutral-color-030: #6B675D;
$neutral-color-040: #868175;
$neutral-color-050: #9E9A90;
$neutral-color-060: #B6B3AB;
$neutral-color-070: #CECBC6;
$neutral-color-080: #E2E0DD;
$neutral-color-090: #F0EFED;
$neutral-color-100: #f5f5f5;

$white: #ffffff;
$black: $neutral-color-000;
$red: #BB0202;

$orange-color-000: #AE4800;
$orange-color-020: #E15D00;
$orange-color-040: #FF6C04;
$orange-color-060: #FF8A37;
$orange-color-080: #FFA86A;
$orange-color-100: #F9D1AD;

$green-color-000: #00431D;
$green-color-020: #00652B;
$green-color-040: #009841;
$green-color-060: #33D578;
$green-color-080: #66FEA7;


$depi-sans: 'Source Sans Pro', sans-serif;
$depv-sans: 'DINPro', sans-serif;

@mixin depi-copy-xxs(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 12/24);
  line-height: calc($base-spacing-unit * 16/24);
  color: $neutral-color-050;
}
@mixin depi-copy-xs(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 14/24);
  line-height: calc($base-spacing-unit * 21/24);
  color: $neutral-color-050;
}
@mixin depi-copy-s(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
  color: $neutral-color-050;
}
@mixin depi-copy-m(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
}
@mixin depi-copy-l(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 21/24);
  line-height: calc($base-spacing-unit * 32/24);
}

@mixin depv-copy-xxs(){
  font-size: calc($base-spacing-unit * 12/24);
  line-height: calc($base-spacing-unit * 16/24);
  color: $neutral-color-050;
}
@mixin depv-copy-xs(){
  font-size: calc($base-spacing-unit * 14/24);
  line-height: calc($base-spacing-unit * 21/24);
  color: $neutral-color-050;
}
@mixin depv-copy-s(){
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
  color: $neutral-color-050;
}
@mixin depv-copy-m(){
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
}
@mixin depv-copy-l(){
  font-size: calc($base-spacing-unit * 21/24);
  line-height: calc($base-spacing-unit * 32/24);
}

$base-spacing-unit: calc((24 / 16) * 1rem); // = 24px

$space-xxs: calc($base-spacing-unit * 8/24); // = 8px
$space-xs: calc($base-spacing-unit * 16/24);
$space-s: calc($base-spacing-unit * 24/24);
$space-m: calc($base-spacing-unit * 32/24);
$space-l: calc($base-spacing-unit * 48/24);
$space-xl: calc($base-spacing-unit * 64/24);
$space-xxl: calc($base-spacing-unit * 96/24); // = 96px

$cell-padding: $space-xxs;
.table-renderer-wrap {
  width: 100%;
  overflow-x:auto;
}

.table-renderer {
  //table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  > * {
    @include depi-copy-m();
    font-family: $depi-sans;
  }

  tr {
    color: $neutral-color-020;

    &:first-child {
      font-weight: bold;
      text-align: left;
    }

    .cell {
      // width: 100%;
      position: relative;
      padding: 16px;
      border-bottom: 1px solid $neutral-color-060;
      vertical-align: top;
      text-align: left;
      min-width: 140px;
    }
  }

  .cell,
  .cell.wrap,
  &.wrap .cell,
  &.wrap .cell.wrap,
  &.nowrap .cell.wrap,
  &.clip .cell.wrap {
    white-space: normal;
  }

  .cell.nowrap,
  &.nowrap .cell,
  &.nowrap .cell.nowrap,
  &.wrap .cell.nowrap,
  &.clip .cell.nowrap {
    white-space: nowrap;
    overflow-x: visible;
  }

  .cell.clip,
  &.clip .cell,
  &.clip .cell.clip,
  &.wrap .cell.clip,
  &.nowrap .cell.clip {
    white-space: nowrap;
    overflow-x: hidden;
  }

  &.read-only .cell {
    cursor: default !important;
  }
}
