$neutral-color-000: #10100E;
$neutral-color-010: #34322E;
$neutral-color-020: #504D46;
$neutral-color-030: #6B675D;
$neutral-color-040: #868175;
$neutral-color-050: #9E9A90;
$neutral-color-060: #B6B3AB;
$neutral-color-070: #CECBC6;
$neutral-color-080: #E2E0DD;
$neutral-color-090: #F0EFED;
$neutral-color-100: #f5f5f5;

$white: #ffffff;
$black: $neutral-color-000;
$red: #BB0202;

$orange-color-000: #AE4800;
$orange-color-020: #E15D00;
$orange-color-040: #FF6C04;
$orange-color-060: #FF8A37;
$orange-color-080: #FFA86A;
$orange-color-100: #F9D1AD;

$green-color-000: #00431D;
$green-color-020: #00652B;
$green-color-040: #009841;
$green-color-060: #33D578;
$green-color-080: #66FEA7;


$depi-sans: 'Source Sans Pro', sans-serif;
$depv-sans: 'DINPro', sans-serif;

@mixin depi-copy-xxs(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 12/24);
  line-height: calc($base-spacing-unit * 16/24);
  color: $neutral-color-050;
}
@mixin depi-copy-xs(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 14/24);
  line-height: calc($base-spacing-unit * 21/24);
  color: $neutral-color-050;
}
@mixin depi-copy-s(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
  color: $neutral-color-050;
}
@mixin depi-copy-m(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
}
@mixin depi-copy-l(){
  font-family: $depi-sans;
  font-size: calc($base-spacing-unit * 21/24);
  line-height: calc($base-spacing-unit * 32/24);
}

@mixin depv-copy-xxs(){
  font-size: calc($base-spacing-unit * 12/24);
  line-height: calc($base-spacing-unit * 16/24);
  color: $neutral-color-050;
}
@mixin depv-copy-xs(){
  font-size: calc($base-spacing-unit * 14/24);
  line-height: calc($base-spacing-unit * 21/24);
  color: $neutral-color-050;
}
@mixin depv-copy-s(){
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
  color: $neutral-color-050;
}
@mixin depv-copy-m(){
  font-size: calc($base-spacing-unit * 16/24);
  line-height: calc($base-spacing-unit * 24/24);
}
@mixin depv-copy-l(){
  font-size: calc($base-spacing-unit * 21/24);
  line-height: calc($base-spacing-unit * 32/24);
}

$base-spacing-unit: calc((24 / 14) * 1rem); // = 24px

$space-xxs: calc($base-spacing-unit * 8/24); // = 8px
$space-xs: calc($base-spacing-unit * 16/24);
$space-s: calc($base-spacing-unit * 24/24);
$space-m: calc($base-spacing-unit * 32/24);
$space-l: calc($base-spacing-unit * 48/24);
$space-xl: calc($base-spacing-unit * 64/24);
$space-xxl: calc($base-spacing-unit * 96/24); // = 96px

$cell-height: 30px;
$cell-padding: $space-xxs;
$button-size: 32px;

$cell-border-color: #B6B3AB;
$button-color: #000;
$selected-cell-background: #F5F5F4;
$insertion-marker-color: #403CFA;
$highlight-color: #403CFA;
$handle-width: 0px; // 20px;
$handle-radius: 0px; // 5px;


@mixin table-text() {
  color: $neutral-color-020;
  @include depi-copy-m();
  font-family: $depi-sans;
}

@mixin addButton() {
  top: 0;
  left: 0;
  display: none;
  line-height: $button-size;
  width: $button-size;
  height: $button-size;
  vertical-align: center;
  text-align: center;
  position: absolute;
  content: "+";
  cursor: pointer;
  color: $button-color;
  border: 2px solid $button-color;
  border-radius: calc(($button-size) / 2);
  &.visible {
    display: block;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-editor {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  @include table-text();

  tr {
    border-bottom: 1px solid $cell-border-color;
    &:first-child {
      font-weight: bold;
    }
    &:last-child {
      border-bottom: none;
    }

    td.table-cell {
      width: 100%;
      position: relative;
      vertical-align: top;
      text-align: left;
      user-select: none;
      border-right: 1px solid $cell-border-color;
      margin: 0;
      padding: 0;
      &:last-child { border-right: none; }
      
      &.selected {
        box-shadow: 0 0 0 500px rgba($selected-cell-background, 1) inset;
      }
      
      .editor-cell {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        .cell-input {
          box-sizing: border-box; /* fit parent width */
          min-height: $cell-height;
          height: 100%;
          line-height: $cell-height;
          overflow: visible;
          padding: 0;
          margin: 0;
          display: block;
          padding: $cell-padding;
          outline: none !important;
          border: none;
          background: none;
          text-align: left;
          width: 100%;
          @include table-text();
        }

        .cell-content {
          display: block;
          width: 100%;
          padding: $cell-padding;
          min-height: $cell-height;
          @include table-text();
        }
      }

      & > div.text {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    
    }
  }

  .cell,
  .cell.wrap,
  &.wrap .cell,
  &.wrap .cell.wrap,
  &.nowrap .cell.wrap,
  &.clip .cell.wrap {
    white-space: normal;
  }

  .cell.nowrap,
  &.nowrap .cell,
  &.nowrap .cell.nowrap,
  &.wrap .cell.nowrap,
  &.clip .cell.nowrap {
    white-space: nowrap;
    overflow-x: visible;
  }

  .cell.clip,
  &.clip .cell,
  &.clip .cell.clip,
  &.wrap .cell.clip,
  &.nowrap .cell.clip {
    white-space: nowrap;
    overflow-x: hidden;
  }

  &.read-only .cell {
    cursor: default !important;
  }
}

.table-editor-wrap {
  width: 100%;
  text-align: center;
  &.active {
    box-shadow: 0 0 3px 0 rgba(#000, 0.125);
  }
}

.insertion-marker {
  content: ' ';
  z-index: 999;
  display: none;
  position: absolute;
  background-color: $insertion-marker-color;

  &.row {
    min-height: 2px;
    min-width: calc(100% + #{$cell-padding});
    // transform: translate(-$cell-padding, 0);
    left: 0;
    &.before {
      top: 0;
    }
    &.after {
      bottom: 0;
    }
  }

  &.column {
    min-width: 2px;
    min-height: calc(100% + #{$cell-padding});
    // transform: translate(0, -$cell-padding);
    top: 0;
    &.before {
      left: 0;
    }
    &.after {
      left: 100%;
    }
  }

  &.active {
    display: block;
  }
}

.column-interaction-area {
  top: 0;
  left: 0;
  border-radius: 5px;
  cursor: default;
  display:block;
  width: 100%;
  min-height: $button-size;
  position: absolute;

  .add-button {
    @include addButton();
    left: 0;
    transform: translate(-50%, 0);
  }

  &.before {
    width: 50%;
    transform: translate(0, -100%);
  }
  &.after {
    transform: translate(50%, -100%);
    .add-button {
      left: 50%;
    }
  }
}

.row-interaction-area {
  border-radius: 5px;
  top: 0;
  left: 0;
  cursor: default;
  position: absolute;
  display: block;
  width: $button-size;
  height: 100%;
  pointer-events: hover;

  .add-button {
    @include addButton();
    top: 0;
    transform: translate(0, -50%);
  }

  &.before {
    height: 50%;
    transform: translate(-100%, 0);
  }
  &.after {
    transform: translate(-100%, 50%);
    .add-button {
      top: 50%;
    }
  }
}

.interaction-handler {
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba($highlight-color, 0.25);
  cursor: pointer;

  &:hover {
    border-color: rgba($highlight-color, 0.75) !important;
  }

  // &.selected {
  //   background-color: #F5F5F4;
  // }

  &.visible {
    opacity: 1;
  }

  &.column {
    min-width: calc(100% + 2px);
    min-height: $handle-width;
    bottom: 10px;
    left: 50%;
    transform: translate(-100%, -100%);

    &.visible {
      transform: translate(-100%, -1px);
    }

    &.first {
      border-top-left-radius: $handle-radius;
      border-bottom-left-radius: $handle-radius;
    }
    &.last {
      border-top-right-radius: $handle-radius;
      border-bottom-right-radius: $handle-radius;
    }
  }

  &.row {
    min-width: $handle-width;
    min-height: calc(100% + 2px);
    top: -50%;
    right: 10px;
    transform: translate(0, -1px);

    &.visible {
      transform: translate(0, -1px);
    }
    
    &.first {
      border-top-left-radius: $handle-radius;
      border-top-right-radius: $handle-radius;
    }
    &.last {
      border-bottom-left-radius: $handle-radius;
      border-bottom-right-radius: $handle-radius;
    }
  }
}


.options {
  background-color: #DDD;
  padding-top: 100px;
  padding: 20px;
  margin-bottom: $button-size;
  text-align: right;
  transform: opacity 0.2s, transform 0.2s;
  transform: translate(0, 0);
  opacity: 0;
  top: 0;
  width: 100%;
  position: absolute;

  .white-icon {
    fill: #fff;
  }

  &.active {
    transform: translate(0, -100%);
    opacity: 1;
    pointer-events: none;
  }

  .disabled {
    cursor: not-allowed;
  }
}

.delete-cell-button {
  svg {
    transform: translate(-7px, 5px);
  }
}
